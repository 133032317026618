import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import VerifyEmailPage from './components/VerifyEmailPage';
import UserProfilePage from './components/UserProfilePage';
import RephraseApp from './components/RephraseApp';
import PricingPage from './components/PricingPage';
import AiRewriterLandingPage from './components/AiRewriterLandingPage';
import { getUserInfo } from './components/api';
import Toast from './components/Toast';

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ isOpen: false, message: '', type: 'success' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      const storedUserInfo = localStorage.getItem('userInfo');

      if (token && storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
        setIsLoading(false);
      } else if (token) {
        try {
          const info = await getUserInfo(token);
          setUserInfo(info);
          localStorage.setItem('userInfo', JSON.stringify(info));
        } catch (error) {
          console.error('Failed to fetch user info:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const showToast = (message, type = 'success') => {
    setToast({ isOpen: true, message, type });
  };

  const hideToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {/* 独立的Landing页面，不使用Layout包裹 */}
        <Route path="/" element={<AiRewriterLandingPage showToast={showToast} />} />
        
        {/* 使用Layout包裹的路由 */}
        <Route element={<Layout userInfo={userInfo} setUserInfo={setUserInfo} />}>
          <Route path="/login" element={<LoginPage setUserInfo={setUserInfo} showToast={showToast} />} />
          <Route path="/register" element={<RegisterPage showToast={showToast} />} />
          <Route path="/verify-email" element={<VerifyEmailPage showToast={showToast} />} />
          <Route
            path="/profile"
            element={
              userInfo ?
              <UserProfilePage userInfo={userInfo} setUserInfo={setUserInfo} showToast={showToast} /> :
              <Navigate to="/login" replace />
            }
          />
          <Route
            path="/deleteai"
            element={<RephraseApp userInfo={userInfo} setUserInfo={setUserInfo} showToast={showToast} />}
          />
          <Route path="/pricing" element={<PricingPage showToast={showToast} />} />
          <Route path="*" element={<Navigate to="/deleteai" replace />} />

        </Route>
      </Routes>
      <Toast
        isOpen={toast.isOpen}
        message={toast.message}
        type={toast.type}
        onClose={hideToast}
      />
    </Router>
  );
};

export default App;