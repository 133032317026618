import React from 'react';
import { X } from 'lucide-react';

const Toast = ({ message, type, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-xl font-bold ${type === 'success' ? 'text-green-600' : 'text-red-600'}`}>
            {type === 'success' ? 'Successful' : 'Failed'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className={`px-4 py-2 rounded ${
              type === 'success' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
            } text-white transition duration-300`}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;