import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getUserInfo } from './api';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';

const LoginPage = ({ setUserInfo, showToast }) => {

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(email, password);
      localStorage.setItem('token', data.access_token);
      const userInfo = await getUserInfo(data.access_token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      setUserInfo(userInfo);
      // showToast('Login successful', 'success');
      navigate('/deleteai');
    } catch (error) {
      showToast(error.message || 'Login failed', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-amber-100 p-8 rounded-lg  mt-10">
      <h2 className="text-2xl font-bold mb-6 text-black">Login</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <EmailInput email={email} setEmail={setEmail} disabled={isLoading} />
        <PasswordInput
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          show={showPassword}
          setShow={setShowPassword}
          disabled={isLoading}
        />
        <button 
          type="submit" 
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed transition duration-300"
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Login'}
        </button>
      </form>
      <p className="mt-6 text-center text-black">
        Don't have an account?{' '}
        <button
          onClick={() => navigate('/register')}
          className="text-blue-600 hover:underline ml-1 font-semibold"
        >
          Register
        </button>
      </p>
    </div>
  );
};

export default LoginPage;