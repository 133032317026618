import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from './api';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import Alert from './Alert';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token'); // 假设令牌存储在 localStorage 中
    console.log(token)
    if (token) {
      navigate('/deleteai');
      return;
    }
  }, [navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setAlert({ message: "Passwords don't match!", type: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      await register(email, password);
      navigate('/verify-email', { state: { email } });
    } catch (error) {
      setAlert({ message: error.message || 'Registration failed', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">

    <div className="bg-amber-100 p-8 rounded-lg  w-96">
        <h2 className="text-2xl font-bold mb-4 text-black">Register</h2>
        {alert.message && <Alert message={alert.message} type={alert.type} />}
        <form onSubmit={handleSubmit}>
          <EmailInput email={email} setEmail={setEmail} disabled={isLoading} />
          <PasswordInput
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            show={showPassword}
            setShow={setShowPassword}
            disabled={isLoading}
          />
          <PasswordInput
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            show={showConfirmPassword}
            setShow={setShowConfirmPassword}
            disabled={isLoading}
            label="Confirm Password"
          />
          <button 
            type="submit" 
            className="w-full bg-blue-500 text-black py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Try for free now'}
          </button>
        </form>
        <p className="mt-4 text-center text-black">
          Already have an account? 
          <button
            onClick={() => navigate('/login')}
            className="text-blue-500 hover:underline ml-1"
          >
            Login
          </button>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;