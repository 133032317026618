import React from 'react';

const UserInfo = ({ userInfo }) => (
    <div className="max-w-md mx-auto text-black">
    <p><strong>Email:</strong> {userInfo.email}</p>
    <p><strong>Remaining Credit:</strong> {userInfo.total_credit}</p>
    <p><strong>You can use arount </strong> {userInfo.total_credit} times</p>

  </div>
);  

export default UserInfo;