import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AiRewriterLandingPage = () => {

    const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center p-6 max-w-6xl mx-auto">
        <div className="text-2xl font-bold text-blue-600">AiDeleter</div>
      </nav>

      {/* Hero Section */}
      <div className="grid md:grid-cols-2 gap-10 items-center max-w-6xl mx-auto px-4 py-10">
        <div>
          <h1 className="text-5xl font-bold text-gray-800 mb-6">
            Transform AI Content into <span className="text-blue-600">Human-Written Text</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Beat AI detection tools like Turnitin. Instantly rewrite AI-generated content to appear 100% human-crafted.
          </p>
          <button 
              type="submit" 
              className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 flex items-center"
              onClick={() => navigate('/register')}
            >
              Get Started →
            </button>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-white py-16">
        <div className="max-w-6xl mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose AiDeleter?</h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center p-6 bg-blue-50 rounded-xl">
              <div className="mx-auto mb-4 text-blue-600 text-6xl">🛡️</div>
              <h3 className="text-xl font-semibold mb-3">Undetectable Rewrites</h3>
              <p>Advanced algorithms guarantee 0% AI detection across platforms.</p>
            </div>

            <div className="text-center p-6 bg-blue-50 rounded-xl">
              <div className="mx-auto mb-4 text-blue-600 text-6xl">✨</div>
              <h3 className="text-xl font-semibold mb-3">Instant Transformation</h3>
              <p>Quickly convert AI-generated text to human-like writing in seconds.</p>
            </div>

            <div className="text-center p-6 bg-blue-50 rounded-xl">
              <div className="mx-auto mb-4 text-blue-600 text-6xl">✅</div>
              <h3 className="text-xl font-semibold mb-3">High-Quality Output</h3>
              <p>Maintain original meaning while ensuring natural, fluent text.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-blue-600 text-white py-16 text-center">
        <h2 className="text-4xl font-bold mb-6">Ready to Bypass AI Detection?</h2>
        <p className="text-xl mb-8">Start your free trial and experience the power of undetectable AI rewriting.</p>
        <button className="bg-white text-blue-600 px-8 py-4 rounded-lg text-xl hover:bg-blue-50"
        onClick={() => navigate('/register')}
        >
          Try Free Now
        </button>
      </div>

      {/* Footer */}
      <footer className="bg-gray-100 py-8">
        <div className="max-w-6xl mx-auto px-6 text-center">
          <p className="text-gray-600">© 2024 Powered by Venus Horizon. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AiRewriterLandingPage;