import React from 'react';

const EmailInput = ({ email, setEmail }) => (
  <div className="mb-4">
    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
    <input
      type="email"
      id="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full px-3 py-2 border border-gray-300 rounded-md text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      required
      placeholder="Enter your email address"
    />
  </div>
);

export default EmailInput;