import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from './api';
import UserInfo from './UserInfo';
import Alert from './Alert';

const UserProfilePage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        setAlert({ message: 'Failed to fetch user information', type: 'error' });
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setTimeout(() => navigate('/login'), 2000);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    navigate('/login');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userInfo) {
    return <div>Redirecting...</div>;
  }

  return (
    <div className="min-h-screen bg-amber-100 flex items-center justify-center">
      <div className="bg-blue-100 p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4 text-black">Welcome, {userInfo.email}</h2>
        {alert.message && <Alert message={alert.message} type={alert.type} />}
        <UserInfo userInfo={userInfo} />
        <button
          onClick={handleLogout}
          className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 mt-4"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfilePage;