import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verify, resendVerification } from './api';
import VerificationInput from './VerificationInput';
import Alert from './Alert';

const VerifyEmailPage = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await verify(email, verificationCode);
      setAlert({ message: 'Email verified successfully. You can now log in.', type: 'success' });
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setAlert({ message: error.message || 'Verification failed', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setIsLoading(true);
    try {
      await resendVerification(email);
      setAlert({ message: 'Verification email resent successfully.', type: 'success' });
    } catch (error) {
      setAlert({ message: error.message || 'Failed to resend verification email.', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <div className="bg-amber-100 p-8 rounded-lg mt-10 w-96">
        <h2 className="text-2xl font-bold mb-4 text-black">Verify Email</h2>
        {alert.message && <Alert message={alert.message} type={alert.type} />}
        <form onSubmit={handleSubmit}>
          <VerificationInput
            onVerificationChange={setVerificationCode}
            onResend={handleResendVerification}
            isLoading={isLoading}
          />
          <button 
            type="submit" 
            className="w-full bg-blue-500 text-black py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Verify'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyEmailPage;