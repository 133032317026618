import React from 'react';

const BuyButton = ({ plan, userInfo, handleBuy }) => {
  const handleClick = (e) => {
    if (plan.name === "Free") {
      // For Free plan, prevent default action and do nothing
      e.preventDefault();
      return;
    }

    if (userInfo) {
      e.preventDefault();
      const email = encodeURIComponent(userInfo.email);
      const linkWithEmail = `${plan.stripeLink}?prefilled_email=${email}`;
      window.open(linkWithEmail, '_blank');
    } else {
      handleBuy(plan.stripeLink);
    }
  };

  const buttonStyle = plan.name === "Free"
    ? "bg-blue-300 cursor-not-allowed text-white font-bold py-2 px-4 rounded transition duration-300"
    : "bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300";

  const buttonText = plan.name === "Free"
    ? "Free Plan"
    : userInfo
      ? `Buy ${plan.name}`
      : 'Login to Purchase';

  return (
    <button
      onClick={handleClick}
      className={buttonStyle}
      disabled={plan.name === "Free"}
    >
      {buttonText}
    </button>
  );
};

export default BuyButton;